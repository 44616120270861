import React from 'react';
import { IIconProps } from '.';

const IconBellAlert = (props: IIconProps) => {
  return (
    <svg
      height={props.height}
      width={props.width}
      xmlns="http://www.w3.org/2000/svg"
      aria-hidden="true"
      focusable="false"
      data-prefix="fas"
      data-icon="user"
      className="svg-inline--fa fa-user fa-w-14"
      role="img"
      viewBox="0 0 448 512"
    >
      <path
        fill="currentColor"
        d="M433.37 334.81c-28-26.62-49.34-54.48-49.34-148.9 0-79.6-63.37-144.5-144-152.36V16a16 16 0 0 0-32 0v17.56C127.35 41.41 64 106.31 64 185.91c0 94.4-21.41 122.28-49.35 148.9a46.47 46.47 0 0 0-11.27 51.24A47.68 47.68 0 0 0 48 416h352a47.67 47.67 0 0 0 44.62-30 46.47 46.47 0 0 0-11.25-51.19zM400 384H48c-14.22 0-21.35-16.47-11.32-26C71.54 324.8 96 287.66 96 185.91 96 118.53 153.22 64 224 64s128 54.52 128 121.91c0 101.34 24.22 138.68 59.28 172.07C421.37 367.56 414.16 384 400 384zm-176 96a32 32 0 0 1-32-32h-32a64 64 0 1 0 128 0h-32a32 32 0 0 1-32 32zm-8.5-224h17a8.13 8.13 0 0 0 8-7.5l7-112a8 8 0 0 0-8-8.5h-31a8 8 0 0 0-8 8.5l7 112a8 8 0 0 0 8 7.5zm8.5 24a24 24 0 1 0 24 24 24 24 0 0 0-24-24z"
      />
    </svg>
  );
};

export default IconBellAlert;
