import UserPermissionModal from './UserPermissionModal';

const GlobalModals = () => {
  return (
    <>
      <UserPermissionModal id="userPermissions" />
    </>
  );
};

export default GlobalModals;
