interface IProps {
  transparent?: boolean;
  color?: string;
  size?: number;
  showDogFood?: boolean;
}

const IconLogo = ({
  color = '#8c81a7',
  size = 600,
  showDogFood = false,
}: IProps) => {
  return (
    <svg
      width={size}
      height={size}
      viewBox="0 0 158.75 158.75"
      version="1.1"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g>
        <g
          id="g26955"
          transform="matrix(0.96096464,0,0,0.98025571,95.575931,46.069451)"
        >
          <g id="g26953">
            <path
              style={{ fill: color, fillOpacity: 1 }}
              d="m 6.50095,-36.898654 v 9.761677 c 7.586067,1.509125 7.587649,5.542188 7.587649,16.323551 0,17.2262797 2.3e-4,17.2263406 -3.508827,17.2263406 -3.5090603,0 -3.5093487,7.937e-4 -3.5093487,-5.4854554 0,-7.9271335 -1.7967193,-11.0436372 -5.768123,-10.0050731 -2.23537461,0.5845625 -3.1636308,3.438054 -3.1636308,9.71723554 -0.1344639,7.96987696 0.00312,16.85287936 0.00825,19.96880736 0.021696,13.454353 8.3573222,13.84542 8.8237508,0.413928 0.2191994,-6.314678 0.2191465,-6.315377 3.6685087,-6.315377 3.449251,0 3.449402,-4.49e-4 3.449402,38.599194 0,38.599676 -5.29e-4,38.399323 -30.94333,38.59971 l -19.973057,-0.508346 v 9.000442 c 3.126356,-0.18564 9.273842,0.44018 19.973057,0.44018 23.2873653,0 23.2874685,5.3e-4 23.2874685,1.9451 0,2.83484 7.8403365,2.72882 8.1695265,-0.11059 0.165669,-1.42889 0.571603,-1.72636 2.761072,-2.02003 5.510689,-0.739112 5.019331,5.9454 5.019331,-68.357069 0,-62.419667 3.954931,-69.227142 -15.8817173,-69.194225 z"
              id="path26949"
            />
            <path
              style={{ fill: color, fillOpacity: 1 }}
              d="m -40.546824,-36.903306 c -0.590857,0.0019 -1.160293,0.0099 -1.708938,0.02585 -17.556491,0.515469 -13.836883,9.019971 -13.836883,68.810786 0,74.7448 -0.497657,67.979806 5.054473,68.72449 2.14998,0.28838 2.036659,0.0329 3.51137,0.0385 0.429395,-4.746223 -0.0661,-23.295669 -0.0661,-29.478021 -0.204727,-4.781048 -0.205156,-10.66562 -0.205156,-17.912085 0,-38.599635 -2.64e-4,-38.599194 22.968106,-38.599194 5.152517,0 9.141534,5.56e-4 12.24163,0.06718 V 6.3472847 c -3.100072,0.06514 -7.0892,0.06563 -12.24163,0.06563 -22.968394,0 -22.968106,-5.56e-5 -22.968106,-17.2263407 0,-17.226272 -1.8e-4,-17.226333 30.94333,-17.226338 7.0717359,0 12.5121645,0.0026 16.72094441,0.208256 v -8.903332 c -4.56875351,0.16742 -10.00635141,0.40101 -16.54782741,0.40101 -10.067689,0 -17.616934,-0.547756 -23.267312,-0.569476 -0.201798,-7.93e-4 -0.400945,-5.29e-4 -0.597898,0 z"
              id="path26951"
            />
          </g>
        </g>
      </g>
      {showDogFood && (
        <g>
          <path
            style={{ fill: color, fillOpacity: 1 }}
            d="m 79.487055,92.367269 c -5.847172,0 -14.078297,9.039281 -14.078297,14.748721 -0.06483,2.57108 1.905101,4.11078 5.223189,4.11078 3.595085,0 5.97462,-1.84911 8.855108,-1.84911 2.909957,0 5.296857,1.84911 8.855109,1.84911 3.248835,0 5.28507,-1.53823 5.28507,-4.10709 -0.06188,-5.70576 -8.290798,-14.752411 -14.140179,-14.752411 z M 68.642862,91.43903 c -0.763956,-2.550448 -3.130966,-4.208016 -5.276231,-3.692329 -2.145264,0.515686 -3.262393,2.999832 -2.498142,5.548805 0.763956,2.556344 3.130966,4.213911 5.27623,3.698224 2.145265,-0.515686 3.256942,-3.005724 2.498143,-5.5547 z m 6.239831,-1.532332 c 2.274186,-0.598569 3.416068,-3.617186 2.541608,-6.878548 -0.87446,-3.261361 -3.425645,-5.237922 -5.708672,-4.70529 -2.274187,0.598568 -3.416068,3.617188 -2.541608,6.878548 0.875934,3.195061 3.424909,5.309386 5.708672,4.70529 z m 20.730678,-2.158525 c -2.145264,-0.449386 -4.512273,1.14188 -5.276229,3.69233 -0.763956,2.551182 0.349931,5.036801 2.495195,5.552491 2.145263,0.449386 4.502694,-1.141885 5.276232,-3.692331 0.761006,-2.55413 -0.417711,-5.036804 -2.495198,-5.55249 z m -11.521955,2.158525 c 2.217462,0.598568 4.834212,-1.510229 5.708672,-4.70529 0.874461,-3.195058 -0.267053,-6.280348 -2.541607,-6.878548 -2.21746,-0.598566 -4.834211,1.510232 -5.708672,4.70529 -0.87667,3.19506 0.265211,6.281824 2.541607,6.878548 z"
            id="path1309"
          />
        </g>
      )}
    </svg>
  );
};

export default IconLogo;
