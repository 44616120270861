import { IIconProps } from '.';

function IconPlane(props: IIconProps) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      aria-hidden="true"
      focusable="false"
      data-prefix="fas"
      data-icon="paper-plane"
      className="svg-inline--fa fa-paper-plane fa-w-16"
      role="img"
      viewBox="0 0 512 512"
      height={props.height}
      width={props.width}
    >
      <path
        fill={props?.fill ? props.fill : 'currentColor'}
        d="M476 3.2L12.5 270.6c-18.1 10.4-15.8 35.6 2.2 43.2L121 358.4l287.3-253.2c5.5-4.9 13.3 2.6 8.6 8.3L176 407v80.5c0 23.6 28.5 32.9 42.5 15.8L282 426l124.6 52.2c14.2 6 30.4-2.9 33-18.2l72-432C515 7.8 493.3-6.8 476 3.2z"
      />
    </svg>
  );
}

export default IconPlane;
